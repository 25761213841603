import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill: Depth Jumps 10 rounds of one 12”, 20″, 24″, 30″, 36″ depth jumps`}</p>
    <p><em parentName="p">{`(or as high as you are comfortable with)`}</em></p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`1 Rope Climb to 15′`}</p>
    <p>{`10 Second Hanging L-Hold`}</p>
    <p>{`15 Box Jumps (24/20″)`}</p>
    <p>{`20 Situps`}</p>
    <p><strong parentName="p">{`*`}{`This Saturday we’ll have free Derby Day classes at 9:00 & 10:00am!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      